import React, { CSSProperties } from 'react'

type DictionnaryProps = {
  expanded: boolean
}

const Dictionnary = (props: DictionnaryProps) => (
  <>
    {[1, 2, 3, 4].map(id => (
      <WordDefinition
        key={`${id}`}
        word={'lorem'}
        definition={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.'
        }
        expanded={props.expanded}
      />
    ))}
  </>
)

type WordDefinitionProps = {
  expanded: boolean
  word: string
  definition: string
}

const WordDefinition = ({ word, definition, expanded }: WordDefinitionProps) => {
  const definitionStyleCollapsed: CSSProperties = {
    margin: 0,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  }
  const definitionStyleExpanded: CSSProperties = {
    margin: 0,
    width: '100%',
    fontSize: '14px',
  }

  return (
    <div
      style={{
        padding: '10px',
      }}>
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
        }}>
        {word}
      </span>
      <p style={expanded ? definitionStyleExpanded : definitionStyleCollapsed}>{definition}</p>
    </div>
  )
}

export default Dictionnary
