import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import styled from '@emotion/styled'
import { Box, Flex } from '../vendor/voidkit/ui'

import { StickyModelType } from '../models/sticky_model'
import StickyToolbox from './sticky_toolbox'
import SimpleFormat from './simple_format'
import StickyContentEditor from './sticky_content_editor'

export type StickyProps = {
  className?: string | boolean
  sticky: {
    id: string
    x: number
    y: number
    width: number
    height: number
    rotation: number
    content: string
    color: string
    position: number
    isSelected: boolean
    isEdited: boolean
  }
  unscaleRatio?: number
  multipleSelect: boolean
  shouldDisplayPosition?: boolean
  // Usable tools
  withDelete?: boolean
  withDuplicate?: boolean
  withEdit?: boolean
  withModelSelect?: boolean
  withResize?: boolean
  withRotation?: boolean
  // End usable tools
  onClick?(event: React.MouseEvent): void
  onContentChange?(newContent: string): void
  // Toolbox callbacks
  onDelete?(): void
  onDuplicate?(): void
  onEdit?(): void
  onSave?(): void
  onModelChange?(newModel: StickyModelType): void
  onResizeChange?(dWith: number, dHeight: number, anchor: string, minWidth?: number, minHeight?: number): void
  onRotationChange?(newRotation: number): void
  // End toolbox callbacks
}

const StickyContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  user-select: none;
  position: absolute;
  padding: 8px;
  /* border: 1px solid rgba(0, 0, 0, ${props => (props.sticky.isSelected ? '0.32' : '0.06')}); */
  top: 0;
  left: 0;
  color: #000;
  z-index: ${props => props.sticky.zIndex + (props.sticky.isSelected ? 1000 : 0)};
  /* box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0 2px 4px 0 rgba(37, 36, 36, 0.1); */
  border: ${props => 2 * props.unscaleRatio}px solid #000;
  /* box-shadow: ${props => (props.sticky.isSelected ? '0 0px 7px 2px #51adee' : '4px 4px 0 0 rgba(227, 227, 227, 0.80)')}; */
  box-shadow: ${props => (props.sticky.isSelected ? '0 0 0 0.2rem rgba(0,123,255,0.35)' : '4px 4px 0 0 rgba(227, 227, 227, 0.80)')};
`

const Content = styled(Box)`
  font-family: 'Caveat Brush', Verdana, Geneva, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  overflow-wrap: break-word;
  width: 100%;
  min-height: 1em;
  z-index: 2;
`

const PositionNumber = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
  font-family: 'Caveat Brush', Verdana, Geneva, sans-serif;
  height: 2em;
  width: 2em;
  text-align: center;
  padding-top: 0.22em;
  border: 3px solid red;
  border-radius: 50%;
`

const Handle = styled(Box)`
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  background-color: transparent;
  z-index: 1;
  cursor: move;
  cursor: -webkit-grab;
  &:active {
    cursor: -webkit-grabbing;
  }
`

// const BackgroundContainer = styled(Box)`
//   &,
//   & svg {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     overflow: hidden;
//     z-index: -1;
//   }
// `

// const StickyBackground = () => (
//   <BackgroundContainer>
//     <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
//       <defs>
//         <pattern id="lines" width="100%" height="1em" patternUnits="userSpaceOnUse">
//           <line x1="0" x2="100%" y1="0" y2="0" strokeLinecap="butt" stroke="#BDBDBD" strokeWidth="1" />
//         </pattern>
//       </defs>

//       <line x1="0" x2="100%" y1="2em" y2="2em" strokeLinecap="butt" stroke="#FFBDBD" strokeWidth="1" />
//       <rect x="0" y="3em" width="100%" height="100%" fill="url(#lines)" stroke="none" />
//     </svg>
//   </BackgroundContainer>
// )

const Sticky = observer(
  ({
    sticky,
    unscaleRatio = 1,
    shouldDisplayPosition,
    withDelete,
    withDuplicate,
    withEdit,
    withResize,
    withRotation,
    onContentChange,
    onDelete,
    onDuplicate,
    onEdit,
    onSave,
    onResizeChange,
    onRotationChange,
    ...props
  }: StickyProps) => {
    const [minSize, setMinSize] = useState({ width: 0, height: 0 })

    const textEditorSizeChanged = (_newWidth: number, newHeight: number) => {
      const computedHeight = newHeight + 50
      // console.log(`sticky height: ${sticky.height}`)
      // console.log(`computed height: ${computedHeight}`)
      const aspectRatio = sticky.width / sticky.height
      const dHeight = computedHeight - sticky.height
      // Updating both width and height results in a weird behaviour
      // const dWidth = dHeight * aspectRatio
      const dWidth = 0

      if (onResizeChange) onResizeChange(dWidth, dHeight, 'se', minSize.width, minSize.height)
    }

    const storeActualStickSize = () => {
      // Store actual sticky size for autoresiez min size
      setMinSize({ width: sticky.width, height: sticky.height })
    }

    return (
      <StickyContainer
        sticky={sticky}
        unscaleRatio={unscaleRatio}
        {...props}
        onDoubleClick={() => {
          storeActualStickSize()
          if (onEdit) {
            onEdit()
          }
        }}
        style={{
          backgroundColor: sticky.color,
          width: `${sticky.width}px`,
          height: `${sticky.height}px`,
          transform: `translate(${sticky.x}px, ${sticky.y}px) rotate(${(!sticky.isEdited && sticky.rotation) || 0}deg)`,
        }}>
        {!sticky.isEdited && <Handle className="handle" />}
        {sticky.isSelected && !props.multipleSelect && (
          <StickyToolbox
            sticky={sticky}
            withDelete={withDelete}
            onDelete={onDelete}
            withDuplicate={withDuplicate}
            onDuplicate={onDuplicate}
            withEdit={withEdit}
            onEdit={onEdit}
            onSave={onSave}
            withRotation={withRotation}
            onRotationChange={onRotationChange}
            withResize={withResize}
            onResizeChange={onResizeChange}
            unscaleRatio={unscaleRatio}
          />
        )}
        {/* <StickyBackground /> */}
        {shouldDisplayPosition && <PositionNumber>{sticky.position}</PositionNumber>}
        <Content className={`sticky-content ${!sticky.isEdited && 'handle'}`}>
          {sticky.isEdited ? (
            <StickyContentEditor
              defaultValue={sticky.content}
              onChange={onContentChange}
              onBlur={onSave}
              onResize={textEditorSizeChanged}
            />
          ) : (
            <SimpleFormat text={sticky.content} />
          )}
        </Content>
      </StickyContainer>
    )
  },
)

export default Sticky
