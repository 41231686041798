import React, { useState, createRef } from 'react'
import { DraggableCore, DraggableEventHandler, DraggableData } from 'react-draggable'
import { observer } from 'mobx-react-lite'
import { FaClone, FaEdit, FaRedo, FaSave, FaTrash } from 'react-icons/fa'

import styled from '@emotion/styled'
import { Box } from '../vendor/voidkit/ui'

import { StickyProps } from './sticky'

import BaseIcon from './icon'

const Icon = styled(BaseIcon)`
  position: absolute;
  /* font-size: 24px; */
`

const DraggableIcon = styled(Icon)`
  cursor: grab;
`

const DeleteIcon = (forwardProps: any) => (
  <Icon
    top={-48 * forwardProps.unscaleRatio}
    left={-48 * forwardProps.unscaleRatio}
    unscaleRatio={forwardProps.unscaleRatio}
    {...forwardProps}>
    <FaTrash />
  </Icon>
)

const DuplicateIcon = (forwardProps: any) => (
  <Icon
    bottom={-48 * forwardProps.unscaleRatio}
    right={-48 * forwardProps.unscaleRatio}
    unscaleRatio={forwardProps.unscaleRatio}
    {...forwardProps}>
    <FaClone />
  </Icon>
)

const EditIcon = (forwardProps: any) => (
  <Icon
    top={-48 * forwardProps.unscaleRatio}
    right={-48 * forwardProps.unscaleRatio}
    unscaleRatio={forwardProps.unscaleRatio}
    {...forwardProps}>
    <FaEdit />
  </Icon>
)

const RotateIcon = (forwardProps: any) => (
  <DraggableIcon
    bottom={-48 * forwardProps.unscaleRatio}
    left={-48 * forwardProps.unscaleRatio}
    unscaleRatio={forwardProps.unscaleRatio}
    className="handleRotation rotate"
    {...forwardProps}>
    <FaRedo />
  </DraggableIcon>
)

const SaveIcon = (forwardProps: any) => (
  <Icon
    top={-48 * forwardProps.unscaleRatio}
    right={-48 * forwardProps.unscaleRatio}
    unscaleRatio={forwardProps.unscaleRatio}
    {...forwardProps}>
    <FaSave />
  </Icon>
)

const HandleResize = styled(Box)`
  position: absolute;
  width: ${props => 24 * props.unscaleRatio}px;
  height: ${props => 24 * props.unscaleRatio}px;
  background: transparent;
`

const HandleResizeBox = styled(Box)`
  margin: ${props => 8 * props.unscaleRatio}px;
  width: ${props => 8 * props.unscaleRatio}px;
  height: ${props => 8 * props.unscaleRatio}px;
  background: #000;
`

const Container = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  & .handleResize {
    &.n {
      cursor: ns-resize;
      top: 0;
      left: 50%;
      transform: translateX(-55%) translateY(-55%);
    }

    &.nw {
      cursor: nwse-resize;
      top: 0;
      left: 0;
      transform: translateX(-55%) translateY(-55%);
    }

    &.ne {
      cursor: nesw-resize;
      top: 0;
      right: 0;
      transform: translateX(55%) translateY(-55%);
    }

    &.s {
      cursor: ns-resize;
      bottom: 0;
      left: 50%;
      transform: translateX(-55%) translateY(55%);
    }

    &.sw {
      cursor: nesw-resize;
      bottom: 0;
      left: 0;
      transform: translateX(-55%) translateY(55%);
    }

    &.se {
      cursor: nwse-resize;
      bottom: 0;
      right: 0;
      transform: translateX(55%) translateY(55%);
    }

    &.w {
      cursor: ew-resize;
      top: 50%;
      left: 0;
      transform: translateX(-55%) translateY(-55%);
    }

    &.e {
      cursor: ew-resize;
      top: 50%;
      right: 0;
      transform: translateX(55%) translateY(-55%);
    }

    &.pointer-n,
    &.pointer-s {
      cursor: ns-resize;
    }

    &.pointer-nw,
    &.pointer-se {
      cursor: nwse-resize;
    }

    &.pointer-ne,
    &.pointer-sw {
      cursor: nesw-resize;
    }

    &.pointer-w,
    &.pointer-e {
      cursor: ew-resize;
    }
  }
`

const ExternalContainer = styled(Box)`
  position: absolute;
`

const SVG = styled('svg')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export type StickyToolboxProps = {
  sticky: StickyProps['sticky']
  unscaleRatio: number
  // Usable tools
  withDelete?: boolean
  withDuplicate?: boolean
  withEdit?: boolean
  withRotation?: boolean
  withResize?: boolean
  withModelSelect?: boolean
  // End usable tools
  onDelete?: StickyProps['onDelete']
  onDuplicate?: StickyProps['onDuplicate']
  onEdit?: StickyProps['onEdit']
  onRotationChange?: StickyProps['onRotationChange']
  onResizeChange?: StickyProps['onResizeChange']
  onModelChange?: StickyProps['onModelChange']
  onSave?(): void
}

const StickyToolbox = observer(({ sticky, ...props }: StickyToolboxProps) => {
  const ref = createRef<HTMLDivElement>()
  const externalRadius = 1.05 * Math.sqrt((sticky.width / 2) * (sticky.width / 2) + (sticky.height / 2) * (sticky.height / 2))

  const deleteEnabled = !sticky.isEdited && props.withDelete && props.onDelete
  const duplicateEnabled = !sticky.isEdited && props.withDuplicate && props.onDuplicate
  const editEnabled = props.withEdit && props.onEdit
  const resizeEnabled = !sticky.isEdited && props.withResize && props.onResizeChange
  const rotationEnabled = !sticky.isEdited && props.withRotation && props.onRotationChange

  const [isRotating, setIsRotating] = useState(false)
  const [isResizing, setIsResizing] = useState(false)

  const onDelete = (event: React.MouseEvent) => {
    if (!deleteEnabled) return

    if (props.onDelete) {
      event.stopPropagation()
      props.onDelete()
    }
  }

  const onDuplicate = (event: React.MouseEvent) => {
    if (!duplicateEnabled) return

    if (props.onDuplicate) {
      event.stopPropagation()
      props.onDuplicate()
    }
  }

  const onEdit = (event: React.MouseEvent) => {
    if (!editEnabled) return

    if (props.onEdit) {
      event.stopPropagation()
      props.onEdit()
    }
  }

  const onSave = (event: React.MouseEvent) => {
    if (!editEnabled) return

    if (props.onSave) {
      event.stopPropagation()
      props.onSave()
    }
  }

  const anchorForRotation = (anchor: string, rotation: number) => {
    const anchorsAntiClockwise = ['e', 'se', 's', 'sw', 'w', 'nw', 'n', 'ne']
    const anchorIndex = anchorsAntiClockwise.indexOf(anchor)
    const rotationOffset = Math.round(rotation / 45)
    return anchorsAntiClockwise[(anchorIndex + rotationOffset + 8 * 1000) % 8]
  }

  const startResize = () => {
    if (resizeEnabled) setIsResizing(true)
  }

  const stopResize = () => {
    if (resizeEnabled) setIsResizing(false)
  }

  const handleResize = (dragInfo: DraggableData, anchor: string) => {
    if (!resizeEnabled) return

    // Rotate delta movement to get into sticky coordinates
    const stickyRotation = (-sticky.rotation * Math.PI) / 180
    const deltaX = Math.cos(stickyRotation) * dragInfo.deltaX - Math.sin(stickyRotation) * dragInfo.deltaY
    const deltaY = Math.sin(stickyRotation) * dragInfo.deltaX + Math.cos(stickyRotation) * dragInfo.deltaY

    if (props.onResizeChange) {
      switch (anchor) {
        case 'nw':
        case 'ne':
        case 'sw':
        case 'se':
          props.onResizeChange(deltaX, deltaY, anchor)
          break
        case 'n':
        case 's':
          props.onResizeChange(0, deltaY, anchor)
          break
        case 'w':
        case 'e':
          props.onResizeChange(deltaX, 0, anchor)
          break
        default:
      }
    }
  }

  const startRotation = () => {
    if (rotationEnabled) setIsRotating(true)
  }
  const stopRotation = () => {
    if (rotationEnabled) setIsRotating(false)
  }

  const handleRotation: DraggableEventHandler = (event, dragInfo) => {
    event.stopPropagation()
    event.preventDefault()
    if (!rotationEnabled) return

    if (ref.current !== null) {
      const bbrect = ref.current.getBoundingClientRect()
      const stickyCenter = {
        x: bbrect.left + bbrect.width / 2,
        y: bbrect.top + bbrect.height / 2,
      }

      const rotationVector = {
        x: dragInfo.lastX - stickyCenter.x,
        y: dragInfo.lastY - stickyCenter.y,
      }

      // TODO Change -135 depending on sticky width/height ratio
      let newRotation = -135 + (Math.atan2(rotationVector.y, rotationVector.x) * 180) / Math.PI
      if (event.shiftKey) {
        const raidus_quantum = 360 / 16
        newRotation = Math.round(newRotation / raidus_quantum) * raidus_quantum
      }

      if (props.onRotationChange) {
        props.onRotationChange(newRotation)
      }
    }
  }

  return (
    <Container ref={ref} isRotating={isRotating} radius={externalRadius} anchorSize={8}>
      <ExternalContainer
        style={{
          top: sticky.height / 2 - externalRadius,
          left: sticky.width / 2 - externalRadius,
          right: sticky.width / 2 - externalRadius,
          bottom: sticky.height / 2 - externalRadius,
        }}>
        {rotationEnabled && isRotating && (
          <SVG width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50%" cy="50%" r={externalRadius - 4} stroke="#DDDDDD" strokeWidth="1" fill="none" />
          </SVG>
        )}
      </ExternalContainer>
      {rotationEnabled && (
        <DraggableCore
          handle=".handleRotation"
          offsetParent={document.body}
          onDrag={handleRotation}
          onStart={startRotation}
          onStop={stopRotation}>
          <RotateIcon unscaleRatio={props.unscaleRatio} />
        </DraggableCore>
      )}
      {deleteEnabled && <DeleteIcon onClick={onDelete} unscaleRatio={props.unscaleRatio} className="delete" />}
      {duplicateEnabled && <DuplicateIcon onClick={onDuplicate} unscaleRatio={props.unscaleRatio} className="duplicate" />}
      {editEnabled &&
        (!sticky.isEdited ? (
          <EditIcon onClick={onEdit} unscaleRatio={props.unscaleRatio} className="edit" />
        ) : (
          <SaveIcon onClick={onSave} unscaleRatio={props.unscaleRatio} className="save" />
        ))}
      {resizeEnabled &&
        ['n', 'nw', 'ne', 's', 'sw', 'se', 'w', 'e'].map(anchor => (
          <DraggableCore
            key={`handleResize-${anchor}`}
            handle=".handleResize"
            offsetParent={document.body}
            onDrag={(e, df) => {
              e.stopPropagation()
              e.preventDefault()
              handleResize(df, anchor)
            }}
            onStart={startResize}
            onStop={stopResize}>
            <HandleResize
              unscaleRatio={props.unscaleRatio}
              className={`handleResize ${anchor} pointer-${anchorForRotation(anchor, sticky.rotation)}`}>
              <HandleResizeBox unscaleRatio={props.unscaleRatio} className={'box'} />
            </HandleResize>
          </DraggableCore>
        ))}
    </Container>
  )
})

export default StickyToolbox
