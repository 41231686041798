import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'

import styled from '@emotion/styled'
import { Box } from '../vendor/voidkit/ui'

const Container = styled(Box)`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  /* -webkit-backdrop-filter: blur(10px); */
  background-color: rgba(0, 0, 0, 0.5);
`

const Surface = styled(Box)`
  min-width: 200px;
`

const Header = styled(Box)`
  background-color: rgb(145, 145, 145);
  border: 1px solid rgb(145, 145, 145);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px;
`

const Content = styled(Box)`
  background-color: #fff;
  border: 1px solid rgb(145, 145, 145);
  padding: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

type ModalProps = {
  title: string
  children: ReactNode
}

class Modal extends React.Component<ModalProps> {
  private el = document.createElement('div')
  private modalRoot = document.getElementById('modal-root')

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    if (this.modalRoot) this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    if (this.modalRoot) this.modalRoot.removeChild(this.el)
  }

  renderModal() {
    return (
      <Container>
        <Surface>
          <Header>{this.props.title}</Header>
          <Content>{this.props.children}</Content>
        </Surface>
      </Container>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), this.el)
  }
}

export default Modal
