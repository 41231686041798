import React, { useState, ReactNode } from 'react'
import styled from '@emotion/styled'
import { Box } from '../vendor/voidkit/ui'

type Point = {
  x: number
  y: number
}

type ScreenPoint = {
  clientX: number
  clientY: number
}

export type BoardTransformProps = {
  scale: number
  minScale: number
  maxScale: number
  translation: Point
  children: ReactNode
  setScale(newScale: number): void
  setTranslation(newTranslation: Point): void
}

const TransformContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const clamp = (min: number, value: number, max: number) => Math.max(min, Math.min(value, max))

const distance = (p1: Point, p2: Point) => {
  const dx = p1.x - p2.x
  const dy = p1.y - p2.y
  return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
}

const midpoint = (p1: Point, p2: Point) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  }
}

const touchPt = (touch: ScreenPoint) => {
  return { x: touch.clientX, y: touch.clientY }
}

const touchDistance = (t0: ScreenPoint, t1: ScreenPoint) => {
  const p0 = touchPt(t0)
  const p1 = touchPt(t1)
  return distance(p0, p1)
}

const coordChange = (coordinate: number, scaleRatio: number) => {
  return scaleRatio * coordinate - coordinate
}

const BoardTransform = ({ scale, setScale, minScale, maxScale, translation, setTranslation, children }: BoardTransformProps) => {
  const ref = React.createRef<HTMLDivElement>()
  const [stopClickPropagation, setStopClickPropagation] = useState(false)
  const defaultPointerState: {
    pointers: ScreenPoint[]
    scale: number
    translation: Point
  } = { scale, translation, pointers: [] }
  const [startPointerInfo, setStartPointerInfo] = useState(defaultPointerState)

  const translatedOrigin = (withTranslation: Point) => {
    if (ref && ref.current) {
      const clientOffset = ref.current.getBoundingClientRect()
      return {
        x: clientOffset.left + withTranslation.x,
        y: clientOffset.top + withTranslation.y,
      }
    }
    return {
      x: 0,
      y: 0,
    }
  }

  const clientPosToTranslatedPos = ({ x, y }: Point, _translation?: Point) => {
    const usedTranslation = _translation || translation
    const origin = translatedOrigin(usedTranslation)
    return {
      x: x - origin.x,
      y: y - origin.y,
    }
  }

  const scaleFromPoint = (newScale: number, focalPt: Point) => {
    const scaleRatio = newScale / (scale !== 0 ? scale : 1)

    const focalPtDelta = {
      x: coordChange(focalPt.x, scaleRatio),
      y: coordChange(focalPt.y, scaleRatio),
    }

    const newTranslation = {
      x: translation.x - focalPtDelta.x,
      y: translation.y - focalPtDelta.y,
    }

    setTranslation(newTranslation)
    setScale(newScale)
  }

  const setPointerState = (pointers: React.TouchList | React.MouseEvent[] = []) => {
    if (pointers.length === 0) {
      setStartPointerInfo({ scale, translation, pointers: [] })
      return
    }

    const flatPointers = []
    for (let i = 0; i < pointers.length; i += 1) {
      const pointer = pointers[i]
      flatPointers.push({
        clientX: pointer.clientX,
        clientY: pointer.clientY,
      })
    }

    setStartPointerInfo({
      scale,
      translation,
      pointers: flatPointers,
    })
  }

  const clampTranslation = (desiredTranslation: Point, bounds: any = {}) => {
    const { x, y } = desiredTranslation
    let { xMax, xMin, yMax, yMin } = bounds
    xMin = xMin !== undefined ? xMin : -Infinity
    yMin = yMin !== undefined ? yMin : -Infinity
    xMax = xMax !== undefined ? xMax : Infinity
    yMax = yMax !== undefined ? yMax : Infinity

    return {
      x: clamp(xMin, x, xMax),
      y: clamp(yMin, y, yMax),
    }
  }

  const onDrag = (pointer: any) => {
    const { translation: _translation, pointers } = startPointerInfo
    const startPointer = pointers[0]
    const dragX = pointer.clientX - startPointer.clientX
    const dragY = pointer.clientY - startPointer.clientY
    const newTranslation = {
      x: _translation.x + dragX,
      y: _translation.y + dragY,
    }

    setTranslation(newTranslation)
    setStopClickPropagation(Boolean(Math.abs(dragX) + Math.abs(dragY) > 2))
  }

  const scaleFromMultiTouch = (e: any) => {
    const startTouches = startPointerInfo.pointers
    const newTouches = e.touches

    // calculate new scale
    const dist0 = touchDistance(startTouches[0], startTouches[1])
    const dist1 = touchDistance(newTouches[0], newTouches[1])
    const scaleChange = dist1 / dist0

    const startScale = startPointerInfo.scale
    const targetScale = startScale + (scaleChange - 1) * startScale
    const newScale = clamp(minScale, targetScale, maxScale)

    // calculate mid points
    const newMidPoint = midpoint(touchPt(newTouches[0]), touchPt(newTouches[1]))
    const startMidpoint = midpoint(touchPt(startTouches[0]), touchPt(startTouches[1]))

    const dragDelta = {
      x: newMidPoint.x - startMidpoint.x,
      y: newMidPoint.y - startMidpoint.y,
    }

    const scaleRatio = newScale / startScale

    const focalPt = clientPosToTranslatedPos(startMidpoint, startPointerInfo.translation)
    const focalPtDelta = {
      x: coordChange(focalPt.x, scaleRatio),
      y: coordChange(focalPt.y, scaleRatio),
    }

    const newTranslation = {
      x: startPointerInfo.translation.x - focalPtDelta.x + dragDelta.x,
      y: startPointerInfo.translation.y - focalPtDelta.y + dragDelta.y,
    }

    setScale(newScale)
    setTranslation(clampTranslation(newTranslation))
  }

  const onWheel = (e: React.WheelEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const scaleChange = 2 ** (e.deltaY * 0.002)

    const newScale = clamp(minScale, scale + (1 - scaleChange), maxScale)

    const mousePos = clientPosToTranslatedPos({ x: e.clientX, y: e.clientY })

    scaleFromPoint(newScale, mousePos)
  }

  const onMouseDown = (e: React.MouseEvent) => {
    setPointerState([e])
  }

  const onTouchStart = (e: React.TouchEvent) => {
    e.preventDefault()
    setPointerState(e.touches)
  }

  const onMouseUp = (e: React.MouseEvent) => {
    setPointerState()
  }

  const onTouchEnd = (e: React.TouchEvent) => {
    setPointerState(e.touches)
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (startPointerInfo.pointers.length === 0) {
      return
    }
    onDrag(e)
  }

  const onTouchMove = (e: React.TouchEvent) => {
    e.preventDefault()

    if (startPointerInfo.pointers.length === 0) {
      return
    }

    if (e.touches.length === 2 && startPointerInfo.pointers.length > 1) {
      scaleFromMultiTouch(e)
    } else if (e.touches.length === 1 && startPointerInfo) {
      onDrag(e.touches[0])
    }
  }

  const touchEndHandler = (e: React.MouseEvent | React.TouchEvent) => {
    if (stopClickPropagation) {
      e.stopPropagation()
      setStopClickPropagation(false)
    }
  }

  return (
    <TransformContainer
      ref={ref}
      onWheel={onWheel}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onClickCapture={touchEndHandler}
      onTouchEndCapture={touchEndHandler}>
      {children}
    </TransformContainer>
  )
}

export default BoardTransform
