import React from 'react'

export type DraggableAPI = {
  draggable(
    data: any,
  ): {
    draggable: boolean
    onDragStart: (event: React.DragEvent<HTMLElement>) => void
  }

  droppable(
    fn: onDnDEventFnType,
  ): {
    onDragEnter: (event: React.DragEvent<HTMLElement>) => void
    onDrop: (event: React.DragEvent<HTMLElement>) => void
    onDragOver: (event: React.DragEvent<HTMLElement>) => void
  }
}

type onDnDEventFnType = (data: any, options: OptionsType) => void

type OptionsType = {
  position: {
    x: number
    y: number
  }
}

type DraggableProps = {
  children(props: DraggableAPI): JSX.Element
}

class Draggable extends React.Component<DraggableProps> {
  // interval: NodeJS.Timeout | undefined = undefined // Re-enable if you want this feature on desktop too

  state = {
    // allowDrag: true, // Re-enable if you want this feature on desktop too
    draggedData: undefined,
    offsetX: 0,
    offsetY: 0,
  }

  draggable = (data: any) => ({
    draggable: true,
    // Re-enable if you want this feature on desktop too
    // draggable: this.state.allowDrag,
    // onMouseDown: (event: React.MouseEvent<HTMLElement>) => this._onMouseDown(event),
    // onMouseUp: (event: React.MouseEvent<HTMLElement>) => this._onMouseUp(event),
    onDragStart: (event: React.DragEvent<HTMLElement>) => this._onDragStart(event, data),
  })

  droppable = (fn: onDnDEventFnType) => ({
    onDragEnter: (event: React.DragEvent<HTMLElement>) => this._onDragEnter(event, fn),
    onDragOver: (event: React.DragEvent<HTMLElement>) => this._onDragOver(event, fn),
    onDrop: (event: React.DragEvent<HTMLElement>) => this._onDrop(event, fn),
  })

  // Re-enable if you want this feature on desktop too
  // _enableDrag = () => {
  //   if (!this.interval) {
  //     this.interval = setTimeout(() => {
  //       console.log('Drag enabled')
  //       this.setState({ allowDrag: true })
  //     }, 150)
  //     window.addEventListener('mouseup', this._disableDrag)
  //   }
  // }

  // Re-enable if you want this feature on desktop too
  // _disableDrag = () => {
  //   console.log('=> Disable Drag')
  //   if (this.interval) {
  //     console.log('Clear timeout')
  //     clearTimeout(this.interval)
  //     this.interval = undefined
  //   }
  //   this.setState({ allowDrag: false })
  //   window.removeEventListener('mouseup', this._disableDrag)
  // }

  // Re-enable if you want this feature on desktop too
  // _onMouseDown = (_event: React.MouseEvent) => {
  //   this._enableDrag()
  // }

  // Re-enable if you want this feature on desktop too
  // _onMouseUp = (_event: React.MouseEvent) => {
  //   this._disableDrag()
  // }

  _onDragStart = (event: React.DragEvent<HTMLElement>, data: any) => {
    // Re-enable if you want this feature on desktop too
    // if (!this.state.allowDrag) {
    //   event.preventDefault()
    //   event.stopPropagation()
    //   return
    // }
    // console.log(`start dragging ${data}`)
    const target = event.currentTarget
    const rect = target.getBoundingClientRect()
    this.setState({ draggedData: data, offsetX: event.clientX - rect.left, offsetY: event.clientY - rect.top })
  }

  _onDragEnter = (event: React.DragEvent<HTMLElement>, _fn: onDnDEventFnType) => {
    event.preventDefault()
  }

  _onDragOver = (event: React.DragEvent<HTMLElement>, _fn: onDnDEventFnType) => {
    event.preventDefault()
  }

  _onDrop = (event: React.DragEvent<HTMLElement>, fn: onDnDEventFnType) => {
    const { draggedData, offsetX, offsetY } = this.state
    const target = event.currentTarget
    const rect = target.getBoundingClientRect()
    const position = {
      x: event.clientX - rect.left - offsetX,
      y: event.clientY - rect.top - offsetY,
    }
    // console.log(`dropped ${draggedData} at ${position.x}:${position.y}`)
    fn(draggedData, { position })
    // Re-enable if you want this feature on desktop too
    // this._disableDrag()
  }

  render() {
    const { children } = this.props
    return children({ draggable: this.draggable, droppable: this.droppable })
  }
}

export default Draggable
