import React, { useState, createRef } from 'react'
import { observer, Observer } from 'mobx-react-lite'
import { DraggableCore } from 'react-draggable'
import BoardTransform from './board_transform'

import { FaBug, FaClone, FaCompress, FaExpand, FaStickyNote, FaTrash, FaCommentsDollar } from 'react-icons/fa'
import { TiSortNumerically } from 'react-icons/ti'
import { MdGridOff, MdGridOn, MdZoomIn, MdZoomOut, MdYoutubeSearchedFor } from 'react-icons/md'

import styled from '@emotion/styled'
import { Box, Flex, Grid } from '../vendor/voidkit/ui'

import Sticky from './sticky'
import { DraggableAPI } from '../vendor/voidkit/ux/draggable'
import { BoardDataType } from '../models/board_data'
import ActionPanel from './action_panel'
import Dropdown from './dropdown'
import SessionQuery from '../queries/session_query'

export type BoardProps = {
  board: BoardDataType
  droppable: DraggableAPI['droppable']
}

const Container = styled(Flex)`
  flex-direction: column;
  flex: 1;
`

const Canvas = styled(Box)`
  position: relative;
  flex: 1;
  overflow: hidden;
`

const Surface = styled(Box)`
  transform-origin: top left;
  transform: translate(${props => `${props.pan.x}px, ${props.pan.y}px`}) scale(${props => `${props.scale.scale}`});
`

const BG = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &:active {
    cursor: move;
    cursor: -webkit-grabbing;
  }
`

const StickyModelButton = styled('button')`
  outline: none;
  margin: 8px;
  width: 32px;
  height: 32px;
  border: 1px solid #bdbdbd;
  opacity: 0.5;
  cursor: pointer;

  &.selected {
    opacity: 1;
    border-color: #000;
  }
`

const Board = observer(({ board, droppable }: BoardProps) => {
  const boardRef = React.createRef<HTMLDivElement>()
  const [pan, setPan] = useState({ x: 0, y: 0 })
  const [scale, setScale] = useState({ scale: 1 })

  const minScale = 0.25
  const maxScale = 4
  const updateScale = (newScale: number) => {
    let finalScale = newScale
    if (finalScale < minScale) {
      finalScale = minScale
    }
    if (finalScale > maxScale) {
      finalScale = maxScale
    }
    const scaleRatio = finalScale / (scale.scale !== 0 ? scale.scale : 1)
    setScale({ scale: finalScale })
    if (boardRef && boardRef.current) {
      const boardSize = boardRef.current.getBoundingClientRect()
      setPan({
        x: pan.x - ((boardSize.width * scale.scale) / 2) * (scaleRatio - 1),
        y: pan.y - ((boardSize.height * scale.scale) / 2) * (scaleRatio - 1),
      })
    }
  }

  return (
    <SessionQuery>
      {({ session }) => (
        <Container>
          <ActionPanel id="board-action-panel">
            <ActionPanel.Bloc flex="1" justifyContent="flex-start">
              {board.selectedStickies.length > 0 && (
                <Dropdown>
                  {({ expanded, toggle }) => (
                    <>
                      <Dropdown.Button>
                        <ActionPanel.Icon id="bap-toggle-sticky-model-menu" onClick={toggle}>
                          <FaStickyNote />
                        </ActionPanel.Icon>
                      </Dropdown.Button>
                      {expanded && (
                        <Dropdown.Menu top="75%" left="15%" minWidth={200} className="menu">
                          <Observer>
                            {() => (
                              <>
                                {board.stickyModels &&
                                  board.stickyModels.map(stickyModel => (
                                    <Dropdown.MenuItem
                                      key={stickyModel.id}
                                      className={`menu-item model-name-${stickyModel.name} model-id-${stickyModel.id}`}
                                      onClick={() => board.setSelectedStickiesModel(stickyModel)}>
                                      <StickyModelButton
                                        className={
                                          board.selectionUsedStickyModels &&
                                          board.selectionUsedStickyModels.find(sm => sm.id === stickyModel.id)
                                            ? 'selected'
                                            : ''
                                        }
                                        style={{ background: stickyModel.color }}
                                      />
                                      {stickyModel.name}
                                    </Dropdown.MenuItem>
                                  ))}
                              </>
                            )}
                          </Observer>
                        </Dropdown.Menu>
                      )}
                    </>
                  )}
                </Dropdown>
              )}
              {board.selectedStickies.length > 1 && (
                <>
                  <ActionPanel.Icon
                    id="bap-remove-selected-stickies"
                    disabled={board.selectedStickies.length === 0 ? true : false}
                    onClick={() => board.removeSelectedStickies()}>
                    <FaTrash />
                  </ActionPanel.Icon>
                  <ActionPanel.Icon
                    id="bap-clone-selected-stickies"
                    disabled={board.selectedStickies.length === 0 ? true : false}
                    onClick={() => board.cloneSelectedStickies()}>
                    <FaClone />
                  </ActionPanel.Icon>
                </>
              )}
            </ActionPanel.Bloc>
            <ActionPanel.Bloc flex="1">
              <ActionPanel.Icon
                id="bap-zoom-out"
                disabled={scale.scale <= minScale ? true : false}
                onClick={() => updateScale(scale.scale / 1.1)}>
                <MdZoomOut fontSize={24} />
              </ActionPanel.Icon>
              <ActionPanel.Icon id="bap-zoom-reset" onClick={() => updateScale(1.0)}>
                <MdYoutubeSearchedFor fontSize={24} />
              </ActionPanel.Icon>
              <ActionPanel.Icon
                id="bap-zoom-in"
                disabled={scale.scale >= maxScale ? true : false}
                onClick={() => updateScale(scale.scale * 1.1)}>
                <MdZoomIn fontSize={24} />
              </ActionPanel.Icon>
            </ActionPanel.Bloc>
            <ActionPanel.Bloc flex="1" justifyContent="flex-end">
              <ActionPanel.Icon
                id="bap-toggle-display-positions"
                onClick={session.toggleDisplayPositions}
                on={session.shouldDisplayPositions}>
                <TiSortNumerically />
              </ActionPanel.Icon>
              <ActionPanel.Icon id="bap-toggle-display-grid" onClick={session.toggleDisplayGrid} on={session.shouldDisplayGrid}>
                {session.shouldDisplayGrid ? <MdGridOn /> : <MdGridOff />}
              </ActionPanel.Icon>
              {!session.isTablet && !session.isMobile && (
                <ActionPanel.Icon id="bap-toggle-debug" onClick={session.toggleDebug} on={session.isDebugEnabled}>
                  <FaBug />
                </ActionPanel.Icon>
              )}
              <ActionPanel.Icon id="bap-toggle-expand-board" onClick={() => (board.isExpanded ? board.compress() : board.expand())}>
                {board.isExpanded ? <FaCompress /> : <FaExpand />}
              </ActionPanel.Icon>
            </ActionPanel.Bloc>
          </ActionPanel>
          <Canvas
            ref={boardRef}
            {...droppable((model, { position }) => {
              board.addSticky({ model, x: (position.x - pan.x) / scale.scale, y: (position.y - pan.y) / scale.scale })
            })}>
            {session.shouldDisplayGrid && <Grid pan={pan} scale={scale.scale} />}
            <BoardTransform
              scale={scale.scale}
              setScale={newScale => setScale({ scale: newScale })}
              minScale={minScale}
              maxScale={maxScale}
              translation={pan}
              setTranslation={newTranslation => setPan({ x: newTranslation.x, y: newTranslation.y })}>
              <BG onClick={() => board.clearStickiesSelection()} />
              <Surface pan={pan} scale={scale}>
                {(board.stickies || []).map(sticky => (
                  <DraggableCore
                    handle=".handle"
                    onDrag={(e, dragInfo) => {
                      e.stopPropagation()
                      e.preventDefault()
                      board.selectAndMoveSelectedStickies(sticky.id, dragInfo.deltaX / scale.scale, dragInfo.deltaY / scale.scale)
                    }}
                    key={`sticky-${sticky.id}`}>
                    <Sticky
                      id={`board-sticky-${sticky.id}`}
                      className={`sticky model-name-${sticky.model.name} model-id-${sticky.model.id}`}
                      sticky={sticky}
                      onClick={(event: React.MouseEvent) =>
                        event.shiftKey ? board.addOrRemoveStickyToSelection(sticky.id) : board.selectSticky(sticky.id)
                      }
                      shouldDisplayPosition={session.shouldDisplayPositions}
                      withDelete
                      withDuplicate
                      withEdit
                      withRotation
                      withResize
                      multipleSelect={board.selectedStickies.length > 1}
                      onContentChange={newContent => sticky.setContent(newContent)}
                      onDelete={() => board.removeSelectedStickies()}
                      onDuplicate={() => board.cloneSelectedStickies()}
                      onEdit={() => board.editSticky(sticky.id)}
                      onBlur={() => board.clearStickyEdition()}
                      onResizeChange={(dWidth, dHeight, anchor, minWidth, minHeight) =>
                        sticky.resize(dWidth / scale.scale, dHeight / scale.scale, anchor, minWidth, minHeight)
                      }
                      onRotationChange={newRotation => board.setSelectedStickiesRotation(newRotation)}
                      unscaleRatio={1 / scale.scale}
                    />
                  </DraggableCore>
                ))}
              </Surface>
            </BoardTransform>
          </Canvas>
        </Container>
      )}
    </SessionQuery>
  )
})

export default Board
