import styled from '@emotion/styled'
import { Box } from '../vendor/voidkit/ui'

const Ellipsis = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default Ellipsis
