import { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import StoreContext from '../services/store'
import { SessionType } from '../stores/session_store'

// Injected Props
type SessionQueryAPI = {
  session: SessionType
}

type SessionQueryProps = {
  children(props: SessionQueryAPI): JSX.Element
}

const SessionQueryWrapper = observer(({ children }: SessionQueryProps) => {
  const store = useContext(StoreContext)
  const { session } = store
  return children({ session })
})

export default SessionQueryWrapper
