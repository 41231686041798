import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { Box, Flex } from '../vendor/voidkit/ui'

type BoardLayoutProps = {
  children: ReactNode
}

const Container = styled(Flex)()

Container.defaultProps = {
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',
}

const LeftPanel = styled(Box)`
  overflow: auto;
  width: ${props => (props.expanded ? 128 : 48)}px;
  flex-shrink: 0;
`

LeftPanel.defaultProps = {
  id: 'board-panel-left',
  bg: '#edf0f2',
}

const CenterPanel = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
`

CenterPanel.defaultProps = {
  id: 'board-panel-center',
  flex: 1,
  overflow: 'hidden',
}

const RightPanel = styled(Box)`
  overflow: auto;
  width: ${props => (props.expanded ? 304 : 48)}px;
  flex-shrink: 0;
`

RightPanel.defaultProps = {
  id: 'board-panel-right',
  bg: '#edf0f2',
}

const BoardLayout = ({ children }: BoardLayoutProps) => <Container>{children}</Container>

BoardLayout.LeftPanel = LeftPanel
BoardLayout.CenterPanel = CenterPanel
BoardLayout.RightPanel = RightPanel

export default BoardLayout
