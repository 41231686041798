import styled from '@emotion/styled'
import { Flex } from '../vendor/voidkit/ui'

export type StickyProps = {
  model: {
    id: string
    width: string
    height: string
    color: string
  }
}

const StickyModel = styled(Flex)`
  font-family: 'Caveat Brush', Verdana, Geneva, sans-serif;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: ${props => props.model.color};
  color: #000;
  box-shadow: 0 2px 4px 0 rgba(37, 36, 36, 0.1);
  user-select: none;
  cursor: move;
  cursor: -webkit-grab;
  &:active {
    cursor: -webkit-grabbing;
  }
`

export default StickyModel
