import React from 'react'

import styled from '@emotion/styled'
import { Flex, Box } from '../vendor/voidkit/ui'
import Expandable, { ExpandableProps } from '../vendor/voidkit/ux/expandable'

const Menu = styled(Flex)`
  position: absolute;
  overflow: auto;
  z-index: 9999;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Container = styled(Box)`
  position: relative;

  ${Menu} {
    display: ${props => !props.expanded && 'none'};
  }
`

const Button = styled(Box)``

Menu.defaultProps = {
  top: '99%',
  flexDirection: 'column',
  bg: '#fff',
  minWidth: 240,
  maxWidth: 500,
  minHeight: 40,
  maxHeight: 312,
  mt: '4px',
  mb: 24,
}

const MenuItem = styled(Flex)`
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #eee;
  }
`

type DropdownProps = ExpandableProps

const Dropdown = ({ children }: DropdownProps) => {
  return (
    <Expandable>
      {args => {
        const { expanded } = args
        return <Container expanded={expanded}>{children(args)}</Container>
      }}
    </Expandable>
  )
}

Dropdown.Button = Button
Dropdown.Menu = Menu
Dropdown.MenuItem = MenuItem

export default Dropdown
