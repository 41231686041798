import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

import { SnapshotOut } from 'mobx-state-tree'

import styled from '@emotion/styled'
import { Flex, Box } from '../vendor/voidkit/ui'

import { StickyModelType } from '../models/sticky_model'
import Sticky from './sticky'

type StickyModelFormProps = {
  stickyModel?: StickyModelType
  onSave({ model, isDefault }: { model: SnapshotOut<StickyModelType>; isDefault: boolean }): void
  onCancel(): void
  onDelete(): void
}

const Form = styled(Flex)`
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
`

Form.defaultProps = {
  as: 'form',
}

const VerticalGroup = styled(Flex)`
  position: relative;
  flex-direction: column;
  margin: 8px;
  justify-content: center;
`

VerticalGroup.defaultProps = {
  alignItems: 'stretch',
}

const HorizontalGroup = styled(Flex)`
  flex-direction: row;
  margin: 8px;
  align-items: stretch;
  justify-content: center;
`

const Label = styled(Box)`
  margin-right: 4px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
`

Label.defaultProps = {
  as: 'label',
  minWidth: 70,
}

const Input = styled(Box)`
  min-height: 32px;
  font-size: 1rem;
  padding: 0;
  border: none;
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-radius: 0;
  background: none;

  outline: none;
  flex: 3;
  font-family: 'Roboto', Georgia, Cambria, 'Times New Roman', Times, serif;

  &:focus {
    border-bottom-color: #46bcf2;
  }
`

Input.defaultProps = {
  as: 'input',
}

const Checkbox = styled(Box)`
  min-height: 32px;
`
Checkbox.defaultProps = {
  as: 'input',
  type: 'checkbox',
}

const TextArea = styled(Input)`
  flex: 3;
`

TextArea.defaultProps = {
  as: 'textarea',
}

const Actions = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  button {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const Button = styled(Flex)`
  user-select: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #46bcf2;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 4px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Spacer = styled(Flex)`
  flex: 1;
`

Button.defaultProps = {
  as: 'button',
}

const DangerButton = styled(Button)`
  background-color: #f24b46;
`

const ColorSwatch = styled(Box)`
  width: 32px;
  height: 32px;
  border: 1px solid #bdbdbd;

  cursor: pointer;
`

const PopOver = styled(Box)`
  position: absolute;
  z-index: 1000;

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const Preview = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 500px;
  max-width: 500px;
  overflow: hidden;

  > div {
    position: unset;
  }
`

const StickyModelForm = ({ stickyModel, onSave, onCancel, onDelete }: StickyModelFormProps) => {
  const [model, setModel] = useState({
    id: (stickyModel && stickyModel.id) || '',
    name: (stickyModel && stickyModel.name) || '',
    description: (stickyModel && stickyModel.description) || '',
    width: (stickyModel && stickyModel.width) || 200,
    height: (stickyModel && stickyModel.height) || 200,
    color: (stickyModel && stickyModel.color) || '#ffffff',
    isDefault: (stickyModel && stickyModel.isDefault) || false,
  })

  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const { isDefault, ...newAttributes } = model
    onSave({ isDefault, model: newAttributes })
  }

  return (
    <Form onSubmit={onSubmit}>
      <HorizontalGroup>
        <VerticalGroup flex={1}>
          <Label>Name:</Label>
          <Input
            name="name"
            required="required"
            type="text"
            value={model.name}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setModel({
                ...model,
                name: e.currentTarget.value,
              })
            }
          />
        </VerticalGroup>
        {(!stickyModel || !stickyModel.isDefault) && (
          <VerticalGroup alignItems="center">
            <Label textAlign="center">Default model:</Label>
            <Checkbox
              name="is-default"
              type="checkbox"
              checked={model.isDefault}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setModel({
                  ...model,
                  isDefault: e.currentTarget.checked,
                })
              }
            />
          </VerticalGroup>
        )}
      </HorizontalGroup>
      <VerticalGroup>
        <Label>Description:</Label>
        <TextArea
          name="description"
          value={model.description}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setModel({
              ...model,
              description: e.currentTarget.value,
            })
          }
        />
      </VerticalGroup>
      <HorizontalGroup>
        <VerticalGroup>
          <Label>Width:</Label>
          <Input
            name="width"
            type="number"
            value={model.width}
            min="120"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (/^[\D]$/.test(event.key)) event.preventDefault()
            }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const value = parseInt(event.currentTarget.value.replace(/\D/g, ''), 10) || 120
              setModel({
                ...model,
                width: value,
              })
            }}
          />
        </VerticalGroup>
        <VerticalGroup>
          <Label>Height:</Label>
          <Input
            name="height"
            type="number"
            value={model.height}
            min="80"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (/^[\D]$/.test(event.key)) event.preventDefault()
            }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const value = parseInt(event.currentTarget.value.replace(/\D/g, ''), 10) || 80
              setModel({
                ...model,
                height: value,
              })
            }}
          />
        </VerticalGroup>
        <VerticalGroup alignItems="center">
          <Label minWidth={0}>Color:</Label>
          <ColorSwatch alignSelf="center" bg={model.color} onClick={() => setDisplayColorPicker(true)} />
          {displayColorPicker && (
            <PopOver>
              <div className="cover" onClick={() => setDisplayColorPicker(false)} />
              <SketchPicker disableAlpha color={model.color} onChangeComplete={color => setModel({ ...model, color: color.hex })} />
            </PopOver>
          )}
        </VerticalGroup>
      </HorizontalGroup>
      <Preview>
        <Sticky
          sticky={{
            id: '1234',
            x: 0,
            y: 0,
            width: model.width,
            height: model.height,
            rotation: -12,
            content: model.name,
            color: model.color,
            position: 1,
            isSelected: false,
            isEdited: false,
          }}
          multipleSelect={false}
        />
      </Preview>
      <Actions>
        {stickyModel && !model.isDefault && (
          <>
            <DangerButton name="delete" type="reset" onClick={onDelete}>
              Delete
            </DangerButton>
            <Spacer />
          </>
        )}
        <DangerButton name="cancel" type="reset" onClick={onCancel}>
          Cancel
        </DangerButton>
        <Button name="save" type="submit">
          Save
        </Button>
      </Actions>
    </Form>
  )
}

export default StickyModelForm
