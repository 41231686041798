import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text as UIText } from '../vendor/voidkit/ui'

import UIIcon from './icon'

type PanelTitleProps = {
  className?: string
  children: ReactNode
  onClick(event: React.MouseEvent): any
}

const Container = styled(Flex)`
  flex-direction: column;
  user-select: none;
  align-items: center;
  margin: 0;
  cursor: ${props => (props.onClick ? 'pointer' : 'normal')};
`

const Text = styled(UIText)`
  margin: 8px 0 0;
  text-align: center;
  flex: 1;
  transform: rotate(${props => (props.vertical && props.flipHorizontally ? -180 : 0)}deg);
  writing-mode: ${props => props.vertical && 'vertical-rl'};
`

Text.defaultProps = {
  as: 'h3',
}

const Icon = styled(UIIcon)`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

const PanelTitle = ({ className, children, onClick }: PanelTitleProps) => (
  <Container className={className} onClick={onClick}>
    {children}
  </Container>
)

PanelTitle.Text = Text
PanelTitle.Icon = Icon

export default PanelTitle
