import React, { ReactNode } from 'react'
import 'typeface-roboto'

import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

import { Box, Flex } from '../vendor/voidkit/ui'

type AppLayoutProps = {
  blured: boolean
  children: ReactNode
}

const Container = styled(Flex)`
  filter: ${props => props.blured && `blur(2px)`};
`

Container.defaultProps = {
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  width: '100vw',
  height: '100vh',
}

const TopPanel = styled(Flex)`
  align-items: center;
  color: rgba(255, 255, 255, 0.87);
  position: fixed;
  width: 100%;
  font-size: 20px;
`

TopPanel.defaultProps = {
  id: 'app-panel-top',
  height: 48,
  bg: '#344955',
  px: 2,
}

const CenterPanel = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

CenterPanel.defaultProps = {
  id: 'app-panel-center',
  mt: TopPanel.defaultProps.height,
  flex: 1,
  overflow: 'hidden',
}

const BottomPanel = styled(Box)`
  overflow: hidden,
  width: ${props => (props.expanded ? 300 : 100)}px;
`

BottomPanel.defaultProps = {
  id: 'app-panel-bottom',
  bg: '#d1d1d6',
}

const AppLayout = ({ blured, children }: AppLayoutProps) => {
  return (
    <Container blured={blured}>
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
          html,
          body {
            margin: 0;
            width: 100%;
            height: 100%;
            font-family: 'Roboto', Georgia, Cambria, 'Times New Roman', Times, serif;
          }
          body {
            overflow: auto;
          }
          * {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
        `}
      />
      {children}
    </Container>
  )
}
AppLayout.TopPanel = TopPanel
AppLayout.CenterPanel = CenterPanel
AppLayout.BottomPanel = BottomPanel

export default AppLayout
