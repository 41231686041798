import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { Box, Flex } from '../vendor/voidkit/ui'

const space = 8

type StickyModelGridProps = {
  children: ReactNode
}

const Grid = styled(Flex)`
  flex-wrap: wrap;
  flex-direction: horizontal;
  margin: ${space / 2}px;
  justify-content: center;
`

const Item = styled(Box)`
  padding: 4px;
  width: ${props => (props.expanded ? 128 - space : 48 - space)}px;
`

const Button = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: ${props => (props.expanded ? 128 - space : 48 - space)}px;
  min-height: 64px;
  padding: 8px;
  color: #000;
  cursor: pointer;
  font-size: 32px;
`

const StickyModelGrid = ({ children }: StickyModelGridProps) => {
  return <Grid>{children}</Grid>
}

StickyModelGrid.Item = Item
StickyModelGrid.Button = Button

export default StickyModelGrid
