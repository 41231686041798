import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { Flex } from '../vendor/voidkit/ui'

type LoadingProps = {
  children: ReactNode
}

const Container = styled(Flex)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > div {
    position: unset;
    transition: all 0.2s;
  }
  .handle {
    opacity: 0;
    transition: all 0.2s 0.2s;
    cursor: wait;
  }
  .loaded .handle {
    opacity: 1;
  }
`

const Loading = ({ children }: LoadingProps) => <Container>{children}</Container>

export default observer(Loading)
