import React from 'react'

import styled from '@emotion/styled'
import { Text } from '../vendor/voidkit/ui'

export type SimpleFormatProps = {
  text: string
}

const Line = styled(Text)()

Line.defaultProps = {
  as: 'p',
  m: 0,
  p: 0,
  minHeight: '1em',
}

const SimpleFormat = ({ text }: SimpleFormatProps) => (
  <>
    {text
      .replace(/\r?\n/g, '\n')
      .split(/\n/g)
      .map((par: string) => (
        <Line key={par}>
          {par.split(/\n/).map((line: string, index: number, all: string[]) => [line, index === 0 && all.length > 1 ? <br /> : null])}
        </Line>
      ))}
  </>
)

export default SimpleFormat
