import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import { Flex, Box } from '../vendor/voidkit/ui'
import autosize from 'autosize'

type StickyContentEditorProps = {
  defaultValue: string
  onChange?(newValue: string): void
  onBlur?(newValue?: string): void
  onResize?(newWidth: number, newHeight: number): void
}

const TextArea = styled(Box)`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  overflow: hidden;
  font-family: 'Caveat Brush';
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  min-height: 1em;
  resize: none;
  line-height: 30px;
`

TextArea.defaultProps = {
  as: 'textarea',
}

const StickyContentEditor = ({ defaultValue, onChange, onBlur, onResize }: StickyContentEditorProps) => {
  const textarea: React.RefObject<HTMLTextAreaElement> = React.createRef()
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (textarea.current) {
      textarea.current.focus()
      autosize(textarea.current)
    }
  })

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
    setValue(newValue)
    if (onChange) onChange(newValue)
    if (onResize) onResize(event.currentTarget.clientWidth, event.currentTarget.clientHeight)
  }

  return (
    <Flex>
      <TextArea
        name="content"
        id="ta"
        ref={textarea}
        value={value}
        onChange={handleChange}
        onBlur={() => onBlur && onBlur((textarea.current && textarea.current.value) || '')}
        rows={1}
      />
    </Flex>
  )
}

export default StickyContentEditor
