import React, { useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa'

import 'typeface-caveat-brush'

import { Expandable, Draggable } from '../vendor/voidkit/ux'

import BoardQuery from '../queries/board_query'

import SessionQuery from '../queries/session_query'

import AppLayout from '../components/app_layout'
import BoardLayout from '../components/board_layout'
import Board from '../components/board'
import StickyModel from '../components/sticky_model'
import StickyModelGrid from '../components/sticky_model_grid'
import PanelTitle from '../components/panel_title'
import Modal from '../components/modal'
import StickyModelForm from '../components/sticky_model_form'
import Ellipsis from '../components/ellipsis'
import Dictionnary from '../components/dictionnary'
import Loading from '../components/loading'
import Sticky from '../components/sticky'
import { StickyModelType } from '../models/sticky_model'

const BoardPage = () => {
  const [hasModal, setModal] = useState(false)
  const [editedModel, setEditedModel] = useState<StickyModelType | undefined>(undefined)

  const editModel = (model: StickyModelType | undefined) => {
    setEditedModel(model)
    setModal(true)
  }

  return (
    <SessionQuery>
      {({ session }) => (
        <BoardQuery>
          {({ loading, error, board }) => {
            if (loading || !session.areFontsLoaded) {
              return (
                <Loading>
                  <Sticky
                    className={session.areFontsLoaded && 'loaded'}
                    sticky={{
                      id: 'loading',
                      x: 0,
                      y: 0,
                      width: session.areFontsLoaded ? 180 : 50,
                      height: session.areFontsLoaded ? 180 : 50,
                      rotation: -12,
                      content: session.areFontsLoaded ? 'Loading...' : '',
                      color: '#ffee88',
                      position: 1,
                      isSelected: false,
                      isEdited: false,
                    }}
                    multipleSelect={false}
                  />
                </Loading>
              )
            }
            if (board) {
              return (
                <AppLayout blured={hasModal}>
                  {!board.isExpanded && <AppLayout.TopPanel>Okkro / {board.name}</AppLayout.TopPanel>}
                  <AppLayout.CenterPanel
                    mt={board.isExpanded ? 0 : AppLayout.CenterPanel.defaultProps && AppLayout.CenterPanel.defaultProps.mt}>
                    <Draggable>
                      {({ draggable, droppable }) => (
                        <BoardLayout>
                          {!board.isExpanded && (
                            <Expandable>
                              {({ expanded, toggle }) => (
                                <BoardLayout.LeftPanel expanded={expanded}>
                                  <PanelTitle onClick={toggle} className="toggle-bl-expand">
                                    <PanelTitle.Icon>{expanded ? <FaChevronLeft /> : <FaChevronRight />}</PanelTitle.Icon>
                                    <PanelTitle.Text vertical={!expanded} flipHorizontally>
                                      Stickies
                                    </PanelTitle.Text>
                                  </PanelTitle>
                                  {expanded && (
                                    <StickyModelGrid>
                                      {board.allStickyModels &&
                                        board.allStickyModels.map((model, idx) => (
                                          <StickyModelGrid.Item key={`sticky-model-${idx}`} expanded={expanded}>
                                            <StickyModel
                                              model={model}
                                              {...draggable(model)}
                                              id={`bl-sticky-model-${model.id}`}
                                              className="sticky-model">
                                              <Ellipsis className="name" onClick={() => editModel(model)}>
                                                {model.name}
                                              </Ellipsis>
                                            </StickyModel>
                                          </StickyModelGrid.Item>
                                        ))}
                                      <StickyModelGrid.Button id="bl-sticky-model-add" onClick={() => setModal(true)}>
                                        <FaPlus />
                                      </StickyModelGrid.Button>
                                      {hasModal && (
                                        <Modal title={`${editedModel ? 'Edit model' : 'New model'}`}>
                                          <StickyModelForm
                                            stickyModel={editedModel}
                                            onSave={({ model, isDefault }) => {
                                              const savedModel = model.id ? board.editStickyModel(model) : board.addStickyModel(model)
                                              if (isDefault && savedModel) board.setDefaultStickyModel(savedModel.id)
                                              setEditedModel(undefined)
                                              setModal(false)
                                            }}
                                            onCancel={() => {
                                              setEditedModel(undefined)
                                              setModal(false)
                                            }}
                                            onDelete={() => {
                                              if (editedModel) {
                                                setEditedModel(undefined)
                                                setModal(false)
                                                board.removeStickyModel(editedModel.id)
                                              }
                                            }}
                                          />
                                        </Modal>
                                      )}
                                    </StickyModelGrid>
                                  )}
                                </BoardLayout.LeftPanel>
                              )}
                            </Expandable>
                          )}
                          <BoardLayout.CenterPanel position="relative">
                            <Board droppable={droppable} board={board} />
                          </BoardLayout.CenterPanel>
                          {!board.isExpanded && (
                            <Expandable>
                              {({ expanded, toggle }) => (
                                <BoardLayout.RightPanel expanded={expanded}>
                                  <PanelTitle onClick={toggle} className="toggle-br-expand">
                                    <PanelTitle.Icon>{expanded ? <FaChevronRight /> : <FaChevronLeft />}</PanelTitle.Icon>
                                    <PanelTitle.Text vertical={!expanded}>Dictionnaire</PanelTitle.Text>
                                  </PanelTitle>
                                  {expanded && <Dictionnary expanded />}
                                </BoardLayout.RightPanel>
                              )}
                            </Expandable>
                          )}
                        </BoardLayout>
                      )}
                    </Draggable>
                  </AppLayout.CenterPanel>
                </AppLayout>
              )
            }
            return <div>{error || 'A problem has occured while loading the Board Data'}</div>
          }}
        </BoardQuery>
      )}
    </SessionQuery>
  )
}

export default BoardPage
