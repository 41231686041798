import { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import StoreContext from '../services/store'
import { BoardDataType } from '../models/board_data'

// Injected Props
type BoardQueryAPI = {
  loading: boolean
  error?: string
  board?: BoardDataType
}

type BoardQueryProps = {
  children(props: BoardQueryAPI): JSX.Element
}

const BoardQueryWrapper = observer(({ children }: BoardQueryProps) => {
  const store = useContext(StoreContext)
  store.boardDataStore.loadBoard('1')
  const { loading, error, board } = store.boardDataStore
  return children({ loading, error, board })
})

export default BoardQueryWrapper
