import styled from '@emotion/styled'
import { Flex } from '../vendor/voidkit/ui'

const Icon = styled(Flex)`
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.12)' : props.on ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.54)')};
  width: ${props => props.width * props.unscaleRatio}px;
  height: ${props => props.height * props.unscaleRatio}px;
  font-size: ${props => props.unscaleRatio}em;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:active {
    color: ${props => !props.disabled && 'rgba(0, 0, 0, 0.87)'};
  }
`

Icon.defaultProps = {
  width: 48,
  height: 48,
}

export default Icon
