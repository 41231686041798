import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { Flex, Box } from '../vendor/voidkit/ui'
import Icon from './icon'

type ActionPanelProps = {
  id?: string
  children: ReactNode
}

const Container = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12); */
`

const Bloc = styled(Flex)``

Bloc.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  px: 12,
}

const Spacer = styled(Box)`
  flex: 1;
`

const ActionPanel = ({ id, children }: ActionPanelProps) => <Container id={id}>{children}</Container>

ActionPanel.Icon = Icon
ActionPanel.Bloc = Bloc
ActionPanel.Spacer = Spacer

export default ActionPanel
