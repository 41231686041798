import React from 'react'

type ExpandableAPI = {
  expanded: boolean
  expand(): void
  collapse(): void
  toggle(): void
}

export type ExpandableProps = {
  children(props: ExpandableAPI): JSX.Element
}

type State = {
  expanded: boolean
}

class Expandable extends React.Component<ExpandableProps, State> {
  state: Readonly<State> = {
    expanded: false,
  }

  onExpand = () => {
    this.setState({ expanded: true })
  }

  onCollapse = () => {
    this.setState({ expanded: false })
  }

  onToggle = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  }

  render() {
    const { expanded } = this.state
    const { children } = this.props

    return children({ expanded, expand: this.onExpand, collapse: this.onCollapse, toggle: this.onToggle })
  }
}

export default Expandable
